import { downloadSvgImage, loadImage, replaceImageColor } from "./Utils";
export class ImageDrawer {
    constructor() {
        this._images = [];
    }
    addImage(container, image) {
        const containerImages = this.getImages(container);
        containerImages === null || containerImages === void 0 ? void 0 : containerImages.images.push(image);
    }
    destroy() {
        this._images = [];
    }
    draw(context, particle, radius, opacity) {
        var _a;
        const image = particle.image, element = image === null || image === void 0 ? void 0 : image.element;
        if (!element) {
            return;
        }
        const ratio = (_a = image === null || image === void 0 ? void 0 : image.ratio) !== null && _a !== void 0 ? _a : 1, pos = {
            x: -radius,
            y: -radius,
        };
        context.globalAlpha = opacity;
        context.drawImage(element, pos.x, pos.y, radius * 2, (radius * 2) / ratio);
        context.globalAlpha = 1;
    }
    getImages(container) {
        const containerImages = this._images.find((t) => t.id === container.id);
        if (!containerImages) {
            this._images.push({
                id: container.id,
                images: [],
            });
            return this.getImages(container);
        }
        else {
            return containerImages;
        }
    }
    getSidesCount() {
        return 12;
    }
    loadShape(particle) {
        if (particle.shape !== "image" && particle.shape !== "images") {
            return;
        }
        const container = particle.container, images = this.getImages(container).images, imageData = particle.shapeData, image = images.find((t) => t.source === imageData.src);
        if (!image) {
            this.loadImageShape(container, imageData).then(() => {
                this.loadShape(particle);
            });
        }
    }
    particleInit(container, particle) {
        var _a;
        if (particle.shape !== "image" && particle.shape !== "images") {
            return;
        }
        const images = this.getImages(container).images, imageData = particle.shapeData, color = particle.getFillColor(), replaceColor = (_a = imageData.replaceColor) !== null && _a !== void 0 ? _a : imageData.replace_color, image = images.find((t) => t.source === imageData.src);
        if (!image) {
            return;
        }
        if (image.loading) {
            setTimeout(() => {
                this.particleInit(container, particle);
            });
            return;
        }
        (async () => {
            var _a, _b;
            let imageRes;
            if (image.svgData && color) {
                imageRes = await replaceImageColor(image, imageData, color, particle);
            }
            else {
                imageRes = {
                    color,
                    data: image,
                    element: image.element,
                    loaded: true,
                    ratio: imageData.width / imageData.height,
                    replaceColor: replaceColor,
                    source: imageData.src,
                };
            }
            if (!imageRes.ratio) {
                imageRes.ratio = 1;
            }
            const fill = (_a = imageData.fill) !== null && _a !== void 0 ? _a : particle.fill, close = (_b = imageData.close) !== null && _b !== void 0 ? _b : particle.close, imageShape = {
                image: imageRes,
                fill,
                close,
            };
            particle.image = imageShape.image;
            particle.fill = imageShape.fill;
            particle.close = imageShape.close;
        })();
    }
    async loadImageShape(container, imageShape) {
        var _a;
        const source = imageShape.src;
        if (!source) {
            throw new Error("Error tsParticles - No image.src");
        }
        try {
            const image = {
                source: source,
                type: source.substring(source.length - 3),
                error: false,
                loading: true,
            };
            this.addImage(container, image);
            const imageFunc = ((_a = imageShape.replaceColor) !== null && _a !== void 0 ? _a : imageShape.replace_color) ? downloadSvgImage : loadImage;
            await imageFunc(image);
        }
        catch (_b) {
            throw new Error(`tsParticles error - ${imageShape.src} not found`);
        }
    }
}
